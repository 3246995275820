import { graphql, PageProps } from 'gatsby';
import React, { FC } from 'react';

import { Hero, HeroData } from '~src/components/sections';
import type { SeoData } from '~src/layouts/components/seo';
import Layout from '~src/layouts/page-layout';
import type { StrapiGalleryPage } from '~src/types/generated/graphql-gatsby';

type Data = {
  strapiGalleryPage: StrapiGalleryPage;
};

const TermsPage: FC<PageProps<Data>> = ({
  data: { strapiGalleryPage },
  location,
}) => {
  const seoData = strapiGalleryPage.seo as SeoData;
  const heroData = strapiGalleryPage.hero as HeroData;

  return (
    <Layout pathname={location.pathname} seoData={seoData}>
      <Hero data={heroData} />
    </Layout>
  );
};

export default TermsPage;

export const termsPageQuery = graphql`
  query TermsPage($locale: String!) {
    strapiGalleryPage(locale: { eq: $locale }) {
      seo {
        title
        description
        featuredImage {
          alt
          image {
            localFile {
              ...FeaturedImg
            }
          }
        }
      }
      hero {
        title
        coverImage {
          alt
          desktop {
            localFile {
              ...DesktopCoverImg
            }
          }
          phone {
            localFile {
              ...PhoneCoverImg
            }
          }
        }
      }
    }
  }
`;
